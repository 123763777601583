<template>
  <div>
    <v-card class="mb-3">
      <v-card-title>Pemasukan</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="rules.valid" :disabled="isLoading">
          <v-dialog
            ref="dialog"
            v-model="form.date.modal"
            :return-value.sync="form.date.value"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date.value"
                :rules="rules.date"
                class="mb-3"
                label="Tanggal"
                prepend-inner-icon="mdi-calendar"
                hide-details="auto"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="form.date.value"
              color="primary"
              locale="id"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn text color="primary" @click="form.date.modal = false">
                Cancel
              </v-btn>

              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.date.value)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-text-field
            v-model="form.reference"
            :rules="rules.reference"
            class="mb-3"
            label="Referensi"
            hide-details="auto"
            outlined
          ></v-text-field>

          <v-select
            v-model="form.destination"
            :rules="rules.destination"
            :items="destinations"
            class="mb-3"
            label="Dari"
            hide-details="auto"
            outlined
          ></v-select>

          <v-autocomplete
            v-if="form.destination === 2"
            v-model="form.branch_code"
            :rules="rules.branch_code"
            :items="branches"
            class="mb-3"
            item-text="br_name"
            item-value="branch_code"
            label="Customer"
            hide-details="auto"
            outlined
          ></v-autocomplete>

          <v-autocomplete
            v-if="form.destination === 3"
            v-model="form.supplier_id"
            :rules="rules.supplier_id"
            :items="suppliers"
            class="mb-3"
            item-text="supp_name"
            item-value="supplier_id"
            label="Supplier"
            hide-details="auto"
            outlined
          ></v-autocomplete>

          <v-select
            v-model="form.bank_id"
            :rules="rules.bank_id"
            :items="banks"
            class="mb-3"
            label="Kas/Bank"
            hide-details="auto"
            item-text="bank_account_name"
            item-value="id"
            outlined
          ></v-select>

          <template v-if="form.bank_id && bankBalance !== null">
            <div class="px-3">
              <div>Saldo Kas/Bank</div>

              <h3 class="mb-3">{{ currency(bankBalance) }}</h3>
            </div>
          </template>

          <v-textarea
            v-model="form.memo"
            class="mb-3"
            label="Catatan"
            hide-details="auto"
            rows="1"
            auto-grow
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        Item

        <v-spacer></v-spacer>

        <expense-item-form
          ref="itemForm"
          title="Item Pemasukan"
          @submit="addItem"
          @update="updateItem"
        ></expense-item-form>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-list v-if="items.length" class="rounded" nav outlined>
          <template v-for="(item, index) in items">
            <v-list-item :key="index" link @click="select(index)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.account_code }} - {{ item.account_name }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-h6">
                  {{ currency(item.amount) }}
                </v-list-item-subtitle>

                <v-list-item-subtitle>{{ item.memo }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="index + 2 <= items.length"
              :key="index + '_divider'"
              class="my-2"
            ></v-divider>
          </template>
        </v-list>

        <div v-else class="text-center">Belum ada item</div>
      </v-card-text>
    </v-card>

    <v-btn
      :loading="isLoading"
      class="mt-3"
      color="primary"
      block
      x-large
      @click="submit"
    >
      Proses
    </v-btn>

    <v-dialog v-model="selectedDialog">
      <v-card>
        <v-card-title>Aksi</v-card-title>

        <v-card-text>
          <v-btn
            class="my-2"
            color="primary"
            block
            large
            @click="editSelectedItem()"
          >
            Edit
          </v-btn>

          <v-btn
            class="my-2"
            color="error"
            block
            large
            @click="deleteSelectedItem()"
          >
            Hapus
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExpenseItemForm from '@/components/ExpenseItemForm.vue'

export default {
  components: { ExpenseItemForm },

  data: () => ({
    isLoading: false,
    form: {
      date: {
        modal: false,
        value: new Date().toISOString().substring(0, 10),
      },
      reference: null,
      destination: 0,
      branch_code: null,
      supplier_id: null,
      bank_id: null,
      memo: null,
    },
    rules: {
      valid: true,
      date: [(v) => !!v || 'Tanggal harus dipilih'],
      reference: [(v) => !!v || 'Referensi harus diisi'],
      destination: [(v) => v !== null || 'Harus memilih tujuan pengeluaran'],
      branch_code: [(v) => !!v || 'Customer harus dipilih'],
      supplier_id: [(v) => !!v || 'Supplier harus dipilih'],
      bank_id: [(v) => !!v || 'Bank harus dipilih'],
    },
    banks: [],
    bankBalance: null,
    destinations: [
      { value: 0, text: 'Lain - lain' },
      { value: 2, text: 'Customer' },
      { value: 3, text: 'Supplier' },
      // { value: 4, text: 'Quick Entry' },
    ],
    branches: [],
    suppliers: [],
    itemHeader: [
      { text: 'Akun', value: 'account_code' },
      { text: 'Nilai', value: 'amount' },
      { text: 'Memo', value: 'memo' },
    ],
    items: [],
    selectedItem: null,
    selectedDialog: false,
  }),

  computed: {
    userId() {
      return this.$cookies.get('session').id
    },
  },

  watch: {
    'form.bank_id'() {
      this.getBankBalance()
    },
  },

  async mounted() {
    this.isLoading = true

    await this.getNextReference()
    await this.getBanks()
    await this.getBranches()
    await this.getSuppliers()

    if (this.$route.query.bankId) {
      this.form.bank_id = this.$route.query.bankId
    }

    this.isLoading = false
  },

  methods: {
    async getNextReference() {
      await this.$http.get('/references/next/2').then((response) => {
        this.form.reference = response.data
      })
    },

    async getBanks() {
      await this.$http.get('/banks').then((response) => {
        this.banks = response.data
      })
    },

    async getBankBalance() {
      if (this.form.bank_id) {
        await this.$http
          .get('/banks/' + this.form.bank_id + '/balance')
          .then((response) => {
            this.bankBalance = response.data
          })
      } else {
        this.bankBalance = null
      }
    },

    async getBranches() {
      await this.$http.get('/customers').then((response) => {
        this.branches = response.data
      })
    },

    async getSuppliers() {
      await this.$http.get('/purchasing/suppliers').then((response) => {
        this.suppliers = response.data
      })
    },

    addItem(item) {
      this.items.push(item)
    },

    updateItem(item) {
      this.$set(this.items, item.index, item)
    },

    currency(value) {
      return Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },

    select(index) {
      this.selectedItem = index
      this.selectedDialog = true
    },

    editSelectedItem() {
      if (this.selectedItem !== null) {
        this.$refs.itemForm.edit(
          this.items[this.selectedItem],
          this.selectedItem
        )
        this.selectedDialog = false
      }
    },

    deleteSelectedItem() {
      if (this.selectedItem !== null) {
        this.items.splice(this.selectedItem, 1)
        this.selectedItem = null
      }

      this.selectedDialog = false
    },

    async submit() {
      this.$refs.form.validate()

      if (!this.rules.valid) {
        return
      }

      if (this.items.length === 0) {
        return
      }

      this.isLoading = true

      const data = {
        ...this.form,
        date: this.form.date.value,
        items: this.items,
        userId: this.userId,
      }

      await this.$http
        .post('/accounting/deposits', data)
        .then(() => {
          this.$router.push('/accounting/banks/journals')
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.hide-thead table thead {
  display: none !important;
}
</style>
